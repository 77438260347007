<template>
  <swiper-slide
    class="h-full w-full overflow-auto no-scrollbar"
    style="width: 90%"
  >
    <div
      class="h-full w-full absolute rounded-lg module p-2 flex flex-col z-30"
      style="transition: all 5s"
    >
      <div class="h-10 flex flex-row justify-start items-center px-1">
        <span class="text-font-gray font-semibold mr-auto">SMU</span>
        <!-- <i class="mdi mdi-filter-variant text-font-gray mr-1"></i>
                <multiselect class="w-32" :multiple="false" v-model="sortBy" label="name"
                             placeholder="Select option" :options="sortOptions" /> -->
      </div>

      <div class="h-auto w-full mb-1">
        <div
          class="h-full w-full flex flex-wrap overflow-x-auto no-scrollbar"
          v-if="legendColors"
        >
          <div class="flex-none" v-for="(pill, k) in legendColors" :key="k">
            <div
              @click="toggleSlSelectedItems(k)"
              class="p-1 m-2px text-3xs flex flex-col justify-center items-center px-1 rounded-md mr-1"
              :style="getOpacity(pill, k)"
            >
              <span class="text-center">{{ pill.name }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!loadSMU"
        class="flex flex-1 min-h-0 overflow-auto no-scrollbar flex-col pb-6"
      >
        <div
          v-if="smuFiltered.length == 0"
          class="h-full flex flex-col justify-center items-center"
        >
          <span class="text-xs text-font-gray">No SL Selected</span>
        </div>

        <div
          v-for="(el, index) in smuFiltered"
          :key="index"
          class="h-32 box flex flex-row mb-2"
          @click="selectSMU(el)"
        >
          <div class="h-full w-1 mr-1">
            <div
              v-if="legendColors[el.id_sl]"
              class="h-full w-1 rounded-full mr-2"
              :style="'background-color:' + legendColors[el.id_sl].color + ';'"
            ></div>
          </div>

          <div class="h-full flex-1 flex flex-col">
            <div class="h-6 flex flex-row justify-start items-center p-1">
              <span class="text-xs font-semibold text-white">{{
                el.name
              }}</span>
            </div>

            <div class="h-6 flex flex-row justify-center items-center">
              <div class="h-1 w-1 rounded-full bg-green mr-1"></div>

              <span class="text-3xs mr-2 text-font-gray"
                >Current Chargeability</span
              >

              <span class="text-3xs mr-2 font-semibold"
                >{{ el.ytd | numberFormat(2) }}%</span
              >

              <div class="h-1 w-1 rounded-full bg-purple mr-1"></div>

              <span class="text-3xs mr-2 text-font-gray"
                >Y-1 Chargeability</span
              >

              <span class="text-3xs font-semibold"
                >{{ el.ytd_1 | numberFormat(2) }}%</span
              >
            </div>

            <div class="flex-1 min-h-0 pt-1">
              <chart-col-compare-small
                chartId="chart"
                :marginchart="true"
                :data="[el.mtd, el.mtd_1]"
                :names="['Current Chargeability', 'Y-1 Chargeability']"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-else class="h-full relative">
        <loader :loading="loadSMU" />
      </div>
    </div>
  </swiper-slide>
</template>

<script>
import { state, actions } from "@/store";

export default {
  name: "SMU",
  data() {
    return {
      sortBy: { name: "Name", value: "name", direction: "asc" },
      sortOptions: this.$optionsFilterPipeline,
      sortOrder: { name: "ASC", value: "asc" },
      loading: false,
      activeOption: [],
      smu: false,
      smuElements: false,
      loadSMU: false,
    };
  },
  methods: {
    getOpacity(pill, k) {
      if (this.slSelectedItems.includes(k)) {
        return (
          "color:" + pill.color + "; background-color:" + pill.color + "15;"
        );
      } else {
        return "display:none";
      }
    },
    toggleSlSelectedItems(item) {
      actions.toggleSlSelectedItems(item);
    },
    setLoading(val) {
      this.loading = val;
    },
    load() {
      this.loadSMU = true;
      this.axios
        .get("chargeability/competencyytd", { params: this.params })
        .then((response) => {
          this.loadSMU = false;
          this.smuElements = response.data.data;
          this.$emit("loadSmus", response.data.data);
        });
    },
    selectSMU(smu) {
      this.smuSelected = smu;
    },
  },
  computed: {
    params() {
      return state.globalParams;
    },
    legendColors() {
      return state.slLegendColors;
    },
    slSelectedItems() {
      return state.slSelectedItems;
    },
    smuFiltered() {
      let arr = [];
      if (this.smuElements) {
        arr = this.smuElements.filter((item) => {
          return this.slSelectedItems.includes(item.id_sl);
        });
      }
      return _.orderBy(arr, [this.sortBy.value], [this.sortBy.direction]);
    },
  },
  watch: {
    params() {
      this.load();
    },
  },
  mounted() {
    if (this.params) this.load();
  },
};
</script>
